/* Sidebar CSS */
.nav-link-custom {
  color: #7c8db5 !important;
  transition: color 0.3s ease !important;
}

.nav-link-custom:hover,
.nav-link-custom.active {
  color: #96298d !important;
}

.btn-verify-custom {
  border: 1px solid #96298d !important;
  color: #96298d !important;
}
.btn-verify-custom:hover,
.btn-verify-custom:active {
  background-color: #96298d !important;
  color: #fff !important;
}

.nav.flex-column.mt-auto {
  margin-top: auto !important;
}

/* Dashboard Header */
.profile-head input {
  margin-bottom: 0 !important;
}
.profile-head input:active,
.profile-head input:focus {
  border: 1px solid #96298d !important;
}
.profile-head {
  justify-content: space-between !important;
}

@media (max-width: 1024px) {
  .dashboardheader-container {
    flex-direction: column;
    gap: 10px;
  }
  .dashboardheader-container .titles {
    text-align: start;
    align-self: flex-start !important;
  }
  .dashboardheader-container .home {
    display: none;
  }
  .profile-head {
    width: 100%;
    justify-content: space-between !important;
  }
  .profile-user {
    display: none;
  }
  .profile-dropdown .dropdown-menu {
    left: -80px;
  }
  .sidebar {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .sidebar.open {
    transform: translateX(0);
    opacity: 1;
  }
}
