/* ------Footer-Css-Start-------------- */
/* footer wraper */
footer {
  position: relative;
}

footer .top_footer {
  background-color: #383939;
  padding: 180px 0 60px 0;
  position: relative;
  overflow: hidden;
}

footer .top_footer.has_bg {
  /* background-image: url(/public/enc/images/footer_bg.html); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* footer logo */
footer .top_footer .logo {
  margin-bottom: 40px;
}

footer .top_footer .logo img {
  width: 150px;
}

footer .top_footer .abt_side li {
  padding: 0 0 10px 0;
}

/* footer social media icon */
footer .top_footer .social_media {
  display: flex;
  margin-top: 20px;
}

/* footer link list */
footer .top_footer .social_media li a {
  display: block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.4s all;
  text-decoration: none;
}

footer .top_footer .social_media li a:hover {
  background-color: #ffffff !important;
  color: #96298d !important;
}

footer .top_footer .try_out {
  margin: auto;
}

footer .app_btn li a {
  padding: 12px 10px;
  background-color: var(--bg-white);
  border: 2px solid #96298d;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
  width: 175px;
  text-align: center;
}

footer .app_btn li a:hover {
  -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
}

footer .app_btn li:last-child {
  margin-top: 20px;
}

footer .bottom_footer {
  background-color: #383939;
}

/* footer heading and text colors variable */
footer h2,
footer h3,
footer p,
footer a {
  color: #ffffff;
}

footer a:hover {
  color: #ffffff;
}

/* footer heading h3 */
footer h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 50px;
  padding-top: 10px;
}

footer ul {
  padding-left: 0;
}

footer .links ul li a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
}

footer .links ul li a:hover {
  color: #fecc18;
}

/* footer last */
footer .bottom_footer {
  padding: 20px 0;
}

footer .bottom_footer p {
  margin-bottom: 0;
  font-size: 15px;
}

footer .bottom_footer .developer_text {
  text-align: right;
}

footer .bottom_footer .developer_text a {
  text-decoration: underline;
}

/* footer go top button */
.go_top {
  position: fixed;
  right: 30px;
  bottom: 75px;
  cursor: pointer;
  transition: 0.4s all;
  opacity: 0;
  z-index: 999;
}

.go_top:hover {
  bottom: 80px;
}
