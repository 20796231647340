/* -----------Css-variable------ */

:root {
  --light-purple: #f6f4fe;
  --purple: #6a49f2;
  --bg-purple: #6a49f2;
  --dark-purple: #32236f;
  --body-text-purple: #3e3f66;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --slider-dots-color: #d4d2dd;
  --light-bg: #dfdaf3;
}

/* ------Common-Css------------- */
/* * {
  outline: 1px solid red;
} */

html {
  scroll-behavior: smooth !important;
  margin: 0;
  overflow-x: hidden !important;
}

body {
  scroll-behavior: smooth !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.7;
  font-family: "Poppins", sans-serif !important;
  color: var(--body-text-purple);
  background-color: var(--light-purple);
  overflow-x: hidden !important;
}

.page_wrapper {
  scroll-behavior: smooth !important;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  background-color: #f6f4fe;
  z-index: -5;
}

a {
  text-decoration: none;
  color: var(--body-text-purple);
}

a:hover {
  text-decoration: none;
  color: var(--body-text-purple);
}

ul,
li {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

button:focus,
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.section_title {
  text-align: center;
}

/* section heading h2 */
.section_title h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--dark-purple);
}

.section_title h2 span {
  color: #96298d;
}

.row_am {
  padding: 50px 0;
}

/* white button */
.white_btn {
  padding: 10px 45px;
  border: 1px solid #96298d;
  color: #96298d;
  border-radius: 50px;
  background-color: var(--bg-white);
  font-weight: 700;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-weight: 500;
}

.white_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: #96298d;
  border-radius: 50px;
  transition: 0.6s all;
  z-index: -1;
}

.white_btn:hover::before {
  width: 110%;
}

.white_btn:hover {
  color: var(--text-white);
}

.highlited_block .white_btn:hover {
  border-color: var(--bg-white);
}

/*------WhiteHeader Btn Animation------*/
@keyframes pulse-white-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 253, 253, 0.7);
  }

  100%,
  30% {
    box-shadow: 0 0 0 12px transparent;
  }
}

@keyframes pulse-white-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  100%,
  30% {
    box-shadow: 0 0 0 20px transparent;
  }
}

/* CTA section Shape animation */

.query_section .banner_shape1,
.banner_shape2,
.banner_shape3 {
  position: absolute;
}

.query_section .banner_shape1 {
  top: 200px;
  left: 15%;
  animation: mymove 10s infinite;
}

.query_section .banner_shape2 {
  top: 100px;
  right: 10%;
  animation: mymove 5s infinite;
}

.query_section .banner_shape3 {
  top: 350px;
  right: 20%;
  animation: mymove 3s infinite;
}

@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
}

/* ----------Other-Page-Css-Start---------- */

/* White Header Css Satart */

.white_header .navbar-expand-lg .navbar-nav .nav-link,
.white_header .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
  color: var(--text-white);
}

.white_header .navbar-expand-lg .navbar-nav .has_dropdown:hover .drp_btn {
  color: #96298d;
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  background-color: var(--bg-white);
  color: #96298d;
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
  animation: pulse-white-medium-sm 3.5s infinite;
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
  animation: pulse-white-small-sm 3.5s infinite;
}

header.fix_style.white_header {
  background-color: #664bece0;
}

/* Bredcrumb Css Start */
.bred_crumb {
  /*background-image: url(/public/enc/images/bread_crumb_bg.png);*/
  /*background: #96298d;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -96px;
}

.bred_crumb::after {
  content: "";
  /* background-image: url(../images/inner_page_banner_overlay.svg); */
  position: absolute;
  bottom: -1px;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: center;
}

.bred_crumb .bred_text {
  text-align: center;
  z-index: 1000;
  position: relative;
}

.bred_crumb .bred_text h1 {
  color: var(--text-white);
  font-size: 55px;
  font-weight: 700;
}

.bred_crumb .bred_text h1 + p {
  color: var(--text-white);
  margin-top: -5px;
}

.bred_crumb .bred_text ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bred_crumb .bred_text ul li {
  margin: 0 5px;
}

.bred_crumb .bred_text ul li a,
.bred_crumb .bred_text ul li span {
  color: var(--text-white);
  font-size: 14px;
  transition: 0.4s all;
}

.bred_crumb .bred_text ul li a:hover {
  text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
  margin-top: 25px;
  position: relative;
  z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
  position: relative;
  max-width: 600px;
  height: 60px;
  margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 1px solid #96298d;
  font-size: 16px;
  padding-left: 30px;
  color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
  color: var(--body-text-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--dark-purple);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: #96298d;
  width: 56px;
  height: 56px;
  padding: 0;
  border-radius: 100px;
  text-align: center;
  line-height: 56px;
  font-size: 23px;
  color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
  background-color: var(--dark-purple);
}

/* ----About App Soluction Section--- */

.app_solution_section .row {
  align-items: center;
}

.app_solution_section .app_text .section_title {
  text-align: left;
  margin-bottom: 20px;
}

.app_solution_section .app_text .section_title h2 {
  letter-spacing: -1px;
}

.app_solution_section .app_text p {
  padding-right: 40px;
}

.app_solution_section .app_images {
  position: relative;
}

.app_solution_section .app_images ul {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.app_solution_section .app_images ul li {
  position: relative;
}

.app_solution_section .app_images ul li a {
  position: relative;
}

.app_solution_section .app_images ul li a .play_icon {
  background-color: rgba(255, 255, 255, 0.1);
  width: 96px;
  height: 96px;
  border-radius: 100px;
  text-align: center;
  margin: 0 auto;
  line-height: 96px;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app_solution_section .app_images ul li img {
  max-width: 100%;
}

.app_solution_section .app_images li:first-child {
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

.app_solution_section .app_images ul li:nth-child(2) {
  left: -5px;
  cursor: pointer;
}

.app_solution_section .app_images ul li:nth-child(3) {
  right: -5px;
}

.app_solution_section .app_images::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 570px;
  border-radius: 100%;
  background-color: var(--bg-white);
  z-index: -1;
}

#terms_of_use ul li,
#privacy_policy .how_to_use_info li {
  list-style-type: disc !important;
}

/* RESPONSIVE VIEW */

/* ------Media-Query-Start--------- */
@media screen and (max-width: 1600px) {
  footer .top_footer.has_bg {
    background-repeat: repeat-y;
    background-position: 0 50px;
  }

  .why_we_section .why_inner {
    margin: 0 15px;
  }
  .youtube-video .modal-dialog {
    max-width: 1040px !important;
  }
}

@media screen and (max-width: 1300px) {
  /* features section */
  .features_section .feature_detail .feature_box {
    width: 400px;
  }
  .features_section .feature_detail .left_data {
    padding-left: 75px;
  }
  .features_section .feature_detail .right_data {
    padding-right: 75px;
  }
}

@media screen and (max-width: 1200px) {
  .row_am {
    padding: 50px 0;
  }

  /* navigation bar section */
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 5px 8px;
  }
  .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    padding: 9px 30px !important;
  }
  .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    right: 5px;
  }
  .banner_section .banner_slider .right_icon {
    right: -30px;
  }

  /* features section */
  .features_section .feature_detail .feature_box {
    width: 300px;
  }
  .features_section .feature_detail .left_data {
    padding-left: 15px;
  }
  .features_section .feature_detail .right_data {
    padding-right: 15px;
  }

  /* about app section */
  .about_app_section .about_text .app_statstic {
    margin: 0 !important;
  }
  .about_app_section .about_text .app_statstic li {
    width: 210px;
    padding-left: 20px;
  }
  .about_app_section .about_text .app_statstic li p:first-child {
    font-size: 30px;
  }

  /* modern ui section */
  .modern_ui_section .ui_images::before {
    width: 475px;
    height: 475px;
  }
  .modern_ui_section .ui_images .right_img img:nth-child(1) {
    margin-left: -60px;
  }
  .modern_ui_section .ui_images .right_img img:nth-child(2) {
    margin-left: -140px;
  }

  /* download app section */
  .free_app_section .container .free_app_inner .free_img img {
    transform: scale(0.8);
  }
  .free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -125px;
  }
  .free_app_section .container .free_app_inner .free_img {
    margin-top: -180px;
  }

  .youtube-video .modal-dialog {
    max-width: 940px !important;
  }

  .app_solution_section .app_images ul li:nth-child(2) {
    width: 45%;
    left: 0;
  }
  .app_solution_section .app_images ul li:nth-child(3) {
    width: 50%;
    right: 0;
  }

  .experts_team_section .experts_box .text span {
    min-height: 50px;
    display: block;
    margin-bottom: 10px;
  }

  .review_list_section .review_box h3 {
    font-size: 16px;
  }
  .review_list_section .review_box p {
    font-size: 14px;
  }
  .review_list_section .review_box .reviewer .text span {
    font-size: 14px;
  }

  .contact_page_section .contact_inner {
    margin-top: -120px;
    justify-content: center !important;
  }
  .contact_page_section .contact_inner .contact_form {
    width: 520px;
    padding: 50px 30px;
  }
  .contact_page_section .contact_inner .contact_info {
    width: 350px;
    margin-top: 20px;
  }
  .contact_page_section .contact_inner .section_title h2 {
    font-size: 34px;
  }

  .bred_crumb {
    min-height: 400px;
  }
  .bred_crumb::after {
    background-size: 100%;
    height: 110px;
  }

  .blog_detail_section .blog_inner_pannel {
    margin-top: -100px;
    padding: 30px;
  }
}

@media screen and (max-width: 992px) {
  /* navigation section */
  .navbar-toggler-icon {
    display: flex;
    align-items: center;
    color: var(--body-text-purple);
    justify-content: flex-end;
  }
  .white_header .toggle-bar {
    color: var(--text-white);
  }
  .free_app_section .container .free_app_inner .free_text .section_title h2,
  .section_title h2 {
    font-size: 30px;
  }
  header.fix_style {
    padding: 5px 0;
  }

  .white_header .navbar-collapse {
    position: absolute;
    top: 110%;
    width: 100%;
  }

  .white_header .navbar-expand-lg .navbar-nav .nav-link,
  .white_header .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    color: var(--body-text-purple);
  }
  .white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    background-color: var(--bg-purple);
    color: var(--text-white);
  }

  .banner_section {
    text-align: center;
  }
  .banner_section .app_btn,
  .banner_section .used_app {
    justify-content: center;
  }

  /* navigation section */
  .navbar-expand-lg .navbar-nav .nav-link {
    margin: 5px 0px;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: flex-start;
  }
  .navbar-toggler {
    line-height: 1.7;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 20px 15px;
    background-color: var(--bg-white);
    border-radius: 15px;
    box-shadow: 0 4px 10px #ede9fe;
  }
  .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .navbar-collapse {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease !important;
  }

  /* banner section */
  .banner_section {
    margin-top: 60px;
  }
  .banner_section .banner_text h1 {
    font-size: 40px;
  }
  .banner_section .banner_slider {
    margin-top: 50px;
  }
  .banner_section .banner_slider .left_icon {
    left: 0;
  }
  .banner_section .banner_slider .right_icon {
    right: 0;
  }

  /* features section */
  .features_section .feature_detail .feature_img {
    width: 275px;
  }
  .features_section .feature_detail .feature_box .data_block {
    margin-bottom: 30px;
  }
  .features_section .feature_detail .feature_box {
    width: 250px;
  }
  .features_section .feature_detail .right_data {
    padding-right: 0;
  }

  /* .features_section .feature_detail .feature_img {
    position: absolute;
    left: 33%;
    transform: translateX(-50%);
    top: 6px;
  } */

  /* about app section */
  .about_app_section .row {
    flex-direction: column-reverse;
  }
  .about_app_section .about_img {
    justify-content: flex-start;
    margin-top: 75px;
    margin-left: 0;
  }
  .about_app_section .about_img::before {
    left: 50%;
  }
  .about_app_section .about_img .screen_img {
    margin-left: -170px;
  }
  .about_app_section .about_text .app_statstic {
    justify-content: flex-start !important;
  }
  .about_app_section .about_text .app_statstic li {
    width: 35%;
    margin-right: 30px;
  }
  .about_app_section .about_img::before {
    left: 35%;
  }

  /* modern ui section */
  .modern_ui_section .ui_images {
    margin-top: 30px;
  }
  .modern_ui_section .ui_text {
    padding-right: 0 !important;
  }
  .modern_ui_section .ui_images .right_img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* how it work section */
  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img {
    width: 280px;
  }
  .how_it_works .yt_video .thumbnil a {
    top: 57%;
  }
  .how_it_works .yt_video .thumbnil a span {
    font-size: 20px;
  }

  /* testimonial section */
  #testimonial_slider {
    max-width: 500px;
  }

  /* pricing section */
  .pricing_section .pricing_pannel .pricing_block {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 600px;
    padding-top: 30px;
  }
  .pricing_section .pricing_pannel .pricing_block .price {
    font-size: 36px;
  }
  .pricing_section .pricing_pannel .pricing_block .benifits li p {
    font-size: 14px;
  }

  /* interface section */
  .interface_section .screen_slider {
    min-height: 550px;
  }

  /* download app section */
  .free_app_section .container .free_app_inner {
    padding: 20px 15px !important;
  }
  .free_app_section .container .free_app_inner .free_img img {
    transform: scale(0.7) !important;
    margin-left: -50px !important;
  }
  .free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -180px !important;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn li a {
    padding: 10px 20px !important;
  }

  /* latest story section */
  .latest_story {
    margin-bottom: 40px;
  }
  .latest_story .story_box .story_text {
    padding: 15px;
  }

  /* footer section */
  footer .top_footer {
    padding: 120px 0 60px 0 !important;
  }
  footer .top_footer .logo,
  footer h3 {
    margin-bottom: 20px;
  }
  footer .abt_side {
    margin-bottom: 50px;
  }
  footer .top_footer .try_out {
    margin-left: 0;
  }
  footer .top_footer .col-md-6 {
    margin-bottom: 15px;
  }
  footer .top_footer.has_bg {
    background-position: center;
  }

  /* Bred Crumb */
  .bred_crumb {
    min-height: 160px !important;
  }
  .bred_crumb .bred_text h1 {
    font-size: 45px !important;
  }
  .bred_crumb::after {
    background-size: 100%;
    height: 90px;
    bottom: -5px;
  }
  .bred_crumb .bred_text .search_bar form .form-group {
    height: 50px;
  }
  .bred_crumb .bred_text .search_bar form .form-group .btn {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }

  .bred_crumb .banner_shape3 {
    top: 240px;
    animation: mymove 3s infinite;
  }

  .app_solution_section .app_images {
    margin-top: 30px;
  }
  .app_solution_section .app_images ul li:nth-child(2) {
    text-align: right;
  }
  .app_solution_section .app_text p {
    padding-right: 0;
  }
  .app_solution_section .app_text .section_title {
    text-align: center;
  }
  .app_solution_section .app_images {
    margin-top: 70px;
  }
  .app_solution_section .app_images::before {
    width: 350px;
    height: 350px;
  }

  .why_we_section .why_inner {
    padding: 35px 0;
    padding-bottom: 0px;
  }
  .why_we_section .why_inner .why_box {
    margin-bottom: 50px;
    text-align: center;
  }
  .why_we_section .why_inner .why_box .icon {
    margin-bottom: 30px;
  }

  .experts_team_section .experts_box .text span {
    min-height: auto;
  }
  .query_inner .white_btn {
    font-size: 14px;
  }

  .blog_list_main .blog_text {
    margin-top: 30px;
  }
  .blog_detail_section .blog_inner_pannel {
    margin-top: -100px;
    padding: 30px;
  }
  .blog_detail_section .blog_inner_pannel .info ul {
    padding-left: 30px;
  }
  .comment_section ul li .comment {
    width: calc(100% - 280px);
    margin-left: 20px;
  }
  .blog_detail_section .blog_inner_pannel .quote_block {
    padding: 55px 30px 35px 30px;
  }

  .contact_page_section .contact_inner {
    flex-direction: column;
    margin-top: 60px;
  }
  .contact_page_section .contact_inner .contact_form {
    width: 100%;
  }
  .contact_page_section .contact_inner .contact_info {
    width: 100%;
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .row_am {
    padding: 30px 0;
  }
  .section_title {
    margin-bottom: 20px;
  }
  .section_title p br {
    display: none;
  }
  .free_app_section .container .free_app_inner .free_text .section_title h2,
  .section_title h2 {
    font-size: 24px;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn {
    flex: 1;
  }
  .free_app_section .container .free_app_inner .free_img img {
    margin-left: 0px !important;
  }

  /* navbar section */
  .navbar {
    padding-top: 15px;
  }
  .navbar-brand img {
    width: 160px;
    height: 60px;
  }

  /* banner section */
  .banner_section {
    margin-top: 60px;
  }
  .banner_section .banner_text h1 {
    font-size: 30px;
  }
  .banner_section .app_btn,
  .banner_section .used_app {
    justify-content: center;
    max-width: 100%;
  }
  .banner_section .app_btn li:last-child {
    margin-left: 0 !important;
  }
  .banner_section .app_btn li {
    margin: 0 15px;
  }
  .banner_section .app_btn li a {
    padding: 10px 20px;
  }
  .banner_section .app_btn li a img {
    height: 25px;
  }
  .banner_section .banner_slider .left_icon {
    left: -30px !important;
  }
  .banner_section .banner_slider .right_icon {
    right: -30px !important;
  }
  .banner_section .banner_slider::before {
    width: 350px;
    height: 350px;
  }

  /* trusted logo section */
  .trusted_section .company_logos {
    padding-top: 0;
  }
  .trusted_section .company_logos img {
    max-width: 70%;
  }

  /* features section */
  .features_section .feature_detail {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 30px;
    margin-top: 0;
  }
  .features_section .feature_detail .feature_img {
    position: relative !important;
    top: auto !important;
    left: auto;
    transform: none !important;
    width: 75%;
    margin: 0 auto !important;
  }
  .features_section .feature_detail .feature_box {
    width: 100%;
    padding: 0 15px;
    text-align: center !important;
  }
  .features_section .feature_detail .left_data {
    text-align: center !important;
    padding-left: 8px !important;
  }
  .features_section .feature_detail .right_data {
    text-align: center !important;
    padding-right: 8px !important;
  }
  .features_section .feature_detail .left_data .data_block .icon {
    margin-right: 0;
  }
  .features_section .feature_detail .right_data .data_block .icon {
    margin-left: 0;
  }
  .features_section .feature_detail .feature_box .data_block {
    margin-bottom: 0;
    margin-top: 30px;
  }

  /* about app section */
  .about_app_section .about_text,
  .about_app_section .about_text .section_title {
    text-align: center;
  }
  .about_app_section .about_text .app_statstic {
    margin: 0 !important;
    justify-content: space-between !important;
  }
  .about_app_section .about_text .app_statstic li {
    max-width: 48% !important;
    margin-right: 0;
    padding: 10px 8px !important;
  }
  .about_app_section .about_text .app_statstic li p:first-child {
    font-size: 18px !important;
  }
  .about_app_section .about_img {
    margin: 0;
    margin-top: 50px;
    justify-content: center;
  }
  .about_app_section .about_img::before {
    width: 350px;
    height: 350px;
  }
  .puprple_btn {
    width: 100% !important;
  }

  /* modern ui section */
  .modern_ui_section .ui_images {
    margin-top: 10px;
  }
  .modern_ui_section .section_title {
    text-align: center;
  }
  .modern_ui_section .design_block li {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .modern_ui_section .design_block li::before {
    position: relative;
    left: auto;
    top: auto;
    display: inline-block;
  }

  .modern_ui_section .ui_images img {
    max-width: 100%;
  }
  .modern_ui_section .ui_images::before {
    width: 300px !important;
    height: 300px !important;
  }
  .modern_ui_section .ui_images .left_img {
    width: 70%;
  }
  .modern_ui_section .ui_images .right_img {
    justify-content: center;
  }
  .modern_ui_section .ui_images .right_img img:nth-child(1) {
    top: 0;
    width: 100px;
  }
  .modern_ui_section .ui_images .right_img img:nth-child(3) {
    margin-top: 0;
  }

  /* how it work section */
  .how_it_works .how_it_inner {
    padding: 50px 15px;
  }
  .how_it_works .step_block ul {
    padding-top: 30px;
  }
  .how_it_works .step_block ul li,
  .how_it_works .step_block ul li:nth-child(2) {
    flex-direction: column;
    padding-left: 30px;
  }
  .how_it_works .step_block ul li .step_text h4 {
    font-size: 18px;
  }
  .how_it_works .step_block ul li::before {
    left: 0;
    transform: none;
    height: calc(100% + 10px);
    top: 40px;
  }
  .how_it_works .step_block ul li:first-child::before {
    height: calc(100% + 50px);
  }
  .how_it_works .step_block ul li:first-child::after {
    left: -5px;
    transform: none;
    display: none;
  }
  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img,
  .how_it_works .step_block ul li:nth-child(2) .step_text,
  .how_it_works .step_block ul li:nth-child(2) .step_img {
    text-align: center !important;
  }
  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img {
    width: 100%;
  }
  .how_it_works .step_block ul li .step_number {
    position: absolute;
    top: -5px;
    left: -23px;
    width: 50px;
    height: 50px;
  }
  .how_it_works .step_block ul li:last-child::before {
    opacity: 0;
  }
  .how_it_works .step_block ul li .step_number h3 {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: -2px;
  }
  .how_it_works .yt_video {
    margin-top: -50px;
  }
  .how_it_works .yt_video .thumbnil {
    height: 300px;
    border-radius: 15px;
  }
  .how_it_works .yt_video .thumbnil img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
  }
  .how_it_works .yt_video .thumbnil a {
    width: 100%;
  }
  .how_it_works .yt_video .thumbnil a .play_btn::after {
    width: 140px;
    height: 140px;
  }
  .how_it_works .yt_video .thumbnil a .play_btn::before {
    width: 120px;
    height: 120px;
  }
  .how_it_works .yt_video .thumbnil a .play_btn img {
    height: auto;
  }

  /* testimonial section */
  .testimonial_section .testimonial_block {
    margin: 0;
  }
  .testimonial_section .testimonial_block .avtar_faces {
    display: none;
  }
  .testimonial_section .testimonial_block .testimonial_slide_box {
    width: 100%;
  }

  /* pricing section */
  .pricing_section .pricing_pannel .pricing_block {
    min-height: auto;
    padding: 30px 15px;
  }
  .pricing_section .pricing_pannel .pricing_block .icon {
    margin-bottom: 15px;
  }
  .pricing_section .contact_text {
    margin-top: -20px;
  }

  /* interface section */
  .interface_section .owl-item .screen_frame_img img {
    transform: scale(1);
  }

  /* download section */
  .free_app_section .container .free_app_inner {
    padding: 50px 15px;
    padding-bottom: 0;
  }
  .free_app_section .container .free_app_inner .free_img {
    margin-top: 0;
    justify-content: center;
  }
  .free_app_section .container .free_app_inner .free_img img {
    max-width: 100% !important;
  }
  .free_app_section .container .free_app_inner .free_text .section_title {
    text-align: center !important;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn {
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn li a {
    width: 100%;
    text-align: center;
  }
  .free_app_section
    .container
    .free_app_inner
    .free_text
    .app_btn
    li:last-child {
    margin-left: 0 !important;
  }

  /* latest story section */
  .latest_story .story_box {
    margin-bottom: 30px;
  }

  /* footer section */
  footer .top_footer {
    padding: 120px 0 60px 0 !important;
  }
  footer .row {
    padding-top: 20px !important;
  }
  footer .links,
  footer .abt_side {
    margin-bottom: 30px;
  }
  footer .top_footer .social_media {
    justify-content: start;
  }
  footer .top_footer .social_media li a {
    margin: 0 5px;
  }
  footer .top_footer .try_out {
    margin-left: 0;
  }
  footer .app_btn {
    display: flex;
    gap: 10px;
  }
  footer .app_btn li:last-child {
    margin-top: 0 !important;
  }
  /* footer .app_btn li a {
    margin: 0 auto;
  } */
  footer .bottom_footer .developer_text {
    text-align: center !important;
    margin-top: 10px;
  }
  footer .go_top {
    right: 10px;
  }
  footer .top_footer .col-md-6 {
    margin-bottom: 0px;
  }
  footer .bottom_footer p {
    text-align: center !important;
  }

  /* animation line section */
  .anim_line,
  .anim_line.dark_bg {
    width: 140% !important;
  }

  /* latest story section */
  .latest_story .story_box .story_img img {
    width: 100%;
  }

  /* go top button section */
  .go_top {
    bottom: 30px;
    z-index: 999;
  }

  .purple_backdrop {
    display: none;
  }

  /* Bread-Crumb */
  .bred_crumb {
    min-height: 120px !important;
  }
  .bred_crumb .bred_text h1 {
    font-size: 34px !important;
  }
  .bred_crumb .bred_text .search_bar form .form-group {
    height: 50px;
  }
  .bred_crumb .bred_text .search_bar form .form-group .btn {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
  .bred_crumb .bred_text {
    padding: 0 15px;
    margin-top: 15px;
  }
  .bred_crumb::after {
    background-size: cover;
    height: 100px;
  }

  .blog_list_main .blog_text .section_title,
  .blog_list_story .story_box {
    text-align: center;
  }

  .why_we_section .why_inner {
    padding-bottom: 10px;
  }

  .experts_team_section .experts_box {
    padding: 20px;
    margin-bottom: 15px;
  }
  .pagination_block ul li:not(:first-child, :last-child) a {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 0 3px;
    font-size: 12px;
  }

  .blog_detail_bredcrumb.bred_crumb .bred_text {
    margin-top: -10px;
  }
  .blog_detail_section .blog_inner_pannel {
    margin-top: -70px;
    padding: 25px 20px;
  }
  .blog_detail_section .blog_inner_pannel .section_title {
    margin-bottom: 20px;
  }
  .blog_detail_section .blog_inner_pannel .section_title h2 {
    font-size: 22px;
  }
  .blog_detail_section .blog_inner_pannel .info h3 {
    font-size: 18px;
  }
  .blog_detail_section .blog_inner_pannel .info ul {
    text-align: left;
    padding-left: 0;
  }
  .blog_detail_section .blog_inner_pannel .two_img {
    margin-bottom: 0;
  }
  .blog_detail_section .blog_inner_pannel .two_img img {
    margin: 10px 0;
  }
  .blog_detail_section .blog_inner_pannel .quote_block .q_icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .blog_detail_section .blog_inner_pannel .quote_block {
    padding: 55px 20px 35px 20px;
  }
  .blog_detail_section .blog_inner_pannel .quote_block h2 {
    font-size: 18px;
    line-height: 1.4;
  }
  .blog_detail_section .blog_inner_pannel .blog_authore {
    flex-direction: column;
  }
  .blog_detail_section .blog_inner_pannel .blog_authore .authore_info {
    margin-bottom: 30px;
  }
  .blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {
    font-size: 18px;
  }

  .comment_section ul li {
    flex-direction: column;
    padding: 20px 0;
    text-align: left;
  }
  .comment_section ul li .comment {
    width: 100%;
    margin-left: 0;
  }
  .comment_section ul li .authore_info {
    width: 100%;
    margin-bottom: 15px;
  }
  .comment_section ul li.replay_comment {
    margin-left: 30px;
  }
  .comment_section ul li .authore_info .text {
    width: auto;
  }

  .comment_form_section form .form-group .form-control {
    height: 45px;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .signup_form {
    width: calc(100% - 30px);
    margin: 0 15px;
  }
  .signup_form form {
    padding: 0 20px;
  }
  .signup_form form .form-group .form-control {
    font-size: 14px;
    height: 50px;
  }
  .signup_form .or_option .google_btn {
    min-width: 275px;
    padding: 12px 10px;
  }
  .signup_section .back_btn {
    top: 15px;
  }
  .signup_section .top_part {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .review_freeapp {
    margin: 0 0 40px 0;
  }
  .review_list_section .review_box .rating ul,
  .review_list_section .review_box .reviewer {
    justify-content: flex-start;
    text-align: center;
  }
  .review_list_section .review_box .reviewer .text {
    width: auto;
    text-align: left;
  }
  .review_list_section .review_box {
    text-align: left;
  }

  .contact_page_section .contact_inner {
    margin-top: 40px;
  }
  .contact_page_section .contact_inner .contact_form {
    padding: 30px !important;
  }
  .contact_page_section .contact_inner .section_title h2 {
    font-size: 24px !important;
  }
  .contact_page_section
    .contact_inner
    .contact_form
    form
    .form-group
    .form-control {
    height: 50px;
    font-size: 16px;
  }
  .contact_page_section .contact_inner .contact_form form .term_check label {
    text-align: left;
    line-height: 1.3;
  }
  .contact_page_section .contact_inner .contact_form form .term_check {
    align-items: flex-start;
  }
  .contact_page_section .contact_inner .contact_info .section_title {
    text-align: center;
  }
  .contact_page_section .contact_inner .contact_info .btn {
    margin-bottom: 30px;
  }
  .contact_page_section .contact_inner .contact_info .contact_info_list li {
    flex-direction: row;
  }
  .contact_page_section
    .contact_inner
    .contact_info
    .contact_info_list
    li
    .img {
    width: 100%;
    margin: 0;
  }
  .contact_page_section
    .contact_inner
    .contact_info
    .contact_info_list
    li
    .text {
    width: 100%;
    margin-top: 10px;
  }

  .map_section {
    margin: 15px 0;
  }

  .waitlist_btn button {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .banner_section .app_btn {
    justify-content: center;
    max-width: 100%;
    margin: auto;
  }
  .features_section .feature_detail .feature_box {
    justify-content: center;
    margin: 0 auto;
  }
  .bred_crumb .banner_shape1,
  .banner_shape2,
  .banner_shape3 {
    display: none;
  }
  .query_section .banner_shape1,
  .banner_shape2,
  .banner_shape3 {
    display: none;
  }

  .features_section .feature_detail .feature_img {
    position: relative !important;
    top: auto !important;
    left: 45% !important;
    transform: translateX(-55%);
    width: 75%;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 479px) {
  .free_app_section .container .free_app_inner .free_text .app_btn {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn li {
    width: 100%;
  }
  .free_app_section .container .free_app_inner .free_text .app_btn li a {
    width: 100%;
    text-align: center;
    padding: 10px !important;
  }
  .free_app_section
    .container
    .free_app_inner
    .free_text
    .app_btn
    li:last-child {
    margin-left: 0 !important;
  }
  /* about app section */
  .about_app_section .about_img::before {
    left: 49%;
  }
  .features_section .feature_detail .feature_img {
    position: relative !important;
    top: auto !important;
    left: 40% !important;
    transform: none !important;
    width: 75%;
    margin: 0 auto !important;
  }
  .contact_page_section .contact_inner .contact_info .contact_info_list li {
    flex-direction: column;
  }
  footer .try_out .app_btn {
    display: flex;
    width: 100%;
    gap: 8px;
  }
  footer .try_out .app_btn li a {
    padding: 12px !important;
  }
}
