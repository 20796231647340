.hero-area-v-2 {
  min-height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 84px;
}

@media only screen and (max-width: 991px) {
  .hero-area-v-2 {
    margin-top: 70px;
  }
}

.hero-area-v-2 .hero-area-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 30px;
}
.hero-area-v-2 .hero-area-wrap > div {
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-wrap {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
}
.hero-area-v-2 .hero-area-left {
  /* position: relative; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* top: 30px;
  width: 500px;
  height: fit-content; */
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-left {
    position: static;
    padding-left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 365px) {
  .hero-area-v-2 .hero-area-left {
    padding-top: 10px;
  }
}
.hero-area-v-2 .hero-area-left .hero-title-v-2 {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (max-width: 335px) {
  .hero-area-v-2 .hero-area-left .hero-title-v-2 {
    width: 300px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-left .hero-title-v-2 {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
  }
}
@media screen and (min-width: 767px) {
  .hero-area-v-2 .hero-area-left .hero-title-v-2 {
    width: 480px;
  }
}
@media screen and (max-width: 335px) {
  .hero-area-v-2 .hero-area-left .hero-title-v-2 {
    width: 300px;
  }
}
.hero-area-v-2 .hero-area-left .hero-title-v-2 .hero-title-bg {
  color: #fff;
  background-color: #000;
  padding: 6px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 100px;
}
@media only screen and (max-width: 767px) {
  .hero-area-v-2 .hero-area-left .hero-title-v-2 {
    font-size: 30px;
    font-weight: 700;
  }
}
.hero-area-v-2 .hero-area-left .hero-title-v-2 .hero-title-accent-v-2 {
  color: #ffbf00;
}
/* .hero-area-v-2 .hero-area-right {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .hero-area-v-2 .hero-area-right {
    flex-basis: 100%;
    position: static;
  }
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-right {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-right img {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-area-v-2 .hero-area-right img {
    padding-top: 50px;
    padding-bottom: 50px;
  }
} */
/* .hero-area-v-2 svg {
  position: absolute;
  width: 1400px;
  height: 750px;
  fill: #fff;
} */
@media only screen and (max-width: 575px) {
  .hero-area-v-2 {
    padding-top: 10px;
  }
  /* .hero-area-v-2 svg {
    position: absolute;
    width: 320px;
    height: 750px;
    fill: #fff;
  } */
}
.hero-area-v-2 .hero-text {
  color: #fff;
  font-size: 19px;
  line-height: 1.6;
  letter-spacing: 0.06em;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-text {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
  }
}
@media screen and (min-width: 767px) {
  .hero-area-v-2 .hero-text {
    width: 480px;
  }
}
@media screen and (max-width: 335px) {
  .hero-area-v-2 .hero-text {
    width: 300px;
  }
}
.hero-area-v-2 .combine-form {
  max-width: 526px;
  /*border: 1px solid $border-color;*/
  border-radius: 16px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.1);
  background-color: #fff;
}
.hero-area-v-2 .combine-form .navigation {
  background-color: #f7f7f6;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.hero-area-v-2 .combine-form:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.hero-area-v-2 .combine-form .form1 {
  padding: 24px;
}
.hero-area-v-2 .combine-form .shape1 {
  position: absolute;
  top: 0;
  left: -40px;
  z-index: -2;
}
.hero-area-v-2 .combine-form.style2:after {
  top: -24px;
  right: -24px;
  bottom: unset;
  height: 430px;
  width: 230px;
}
.hero-area-v-2 .combine-form.style2 .shape1 {
  bottom: 0;
  left: -40px;
  top: unset;
}
.hero-area-v-2 .form-tab {
  border-bottom: 1px solid #dee2e6;
  /*padding: 24px 10px;*/
  position: relative;
  z-index: 2;
}
.hero-area-v-2 .form-tab .nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: none;
  padding: 24px 10px;
  flex: 1;
}
.hero-area-v-2 .form-tab .nav-link i {
  margin-right: 4px;
}
.hero-area-v-2 .form-tab .nav-link:not(:last-child):hover,
.hero-area-v-2 .form-tab .nav-link:not(:last-child).active {
  border: none;
  color: #fecc18;
  font-weight: 800;
  background-color: #3d3e3e;
  border-top-left-radius: 16px;
  border-top-right-radius: 0px;
}
.hero-area-v-2 .form-tab .nav-link:is(:last-child):hover,
.hero-area-v-2 .form-tab .nav-link:is(:last-child).active {
  border: none;
  color: #fecc18;
  font-weight: 800;
  background-color: #3d3e3e;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.hero-area-v-2 .form1 .row {
  --bs-gutter-x: 16px;
}
.hero-area-v-2 .form1 .form-title {
  margin-top: -0.3em;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #201d1d;
}
.hero-area-v-2 .form1 .form-text {
  font-size: 18px;
  margin-bottom: 24px;
  max-width: 500px;
  color: #201d1d;
}
.hero-area-v-2 .form1 .ot-btn {
  width: 100%;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
}
.hero-area-v-2 .form1 .ot-btn:before {
  display: none;
}
.hero-area-v-2 .form1 .ot-btn:hover {
  border-color: #201d1d;
  background-color: #201d1d;
}
.hero-area-v-2 .download-buttons {
  display: flex;
  gap: 10px;
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .download-buttons {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .hero-area-v-2 .download-buttons {
    justify-content: center;
  }

  .hero-area-v-2 .download-buttons img {
    max-width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .download-buttons a:first-child {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .hero-area-v-2 .download-buttons a:first-child {
    margin-bottom: 10px;
  }
}
.hero-area-v-2 #myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  -o-object-fit: cover;
  object-fit: cover;
}

/* .form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
} */

.toggle-password {
  position: absolute;
  right: 40px;
  top: 67.5%;
  transform: translateY(-50%);
  z-index: 10;
}

@media only screen and (max-width: 991px) and (max-width: 767px) {
  .toggle-password {
    top: 70.3%;
  }
}

.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: #73dc96;
  display: block;
}
.form-messages.error {
  color: #dc3545;
  display: block;
}

.form-button {
  width: 100%;
  background-color: #fecc18;
  color: #3b3b3b;
}

input {
  height: 60px;
  padding: 16px;
  margin-bottom: 14px !important;
  color: #212529;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  border: 1px solid #dee2e6;
  width: 100%;
  box-shadow: none;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #9b9ca0;
}
input::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #9b9ca0;
}
input::placeholder,
.form-control::placeholder {
  color: #9b9ca0;
}
input:focus,
.form-control:focus {
  outline: 0 !important;
  border-color: #fecc18 !important;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.button.big {
  text-align: center;
}

.hero-area-right .input-group-text {
  padding: 1.3rem 0.75rem !important;
}

.hero-area-v-2 .hero-area-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-area-v-2 .hero-area-right .hero-area-right-content {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-right {
    overflow: hidden;
    margin-top: 50px;
  }
}
.hero-area-v-2 .hero-area-right .download-phone-img {
  border-radius: 39px;
}
.hero-area-v-2 .hero-area-right .download-blob {
  position: absolute;
  left: 48px;
  top: -2px;
  z-index: -1;
  width: 200% !important;
  max-width: 500px !important;
  transform: rotate(279deg);
}
@media screen and (max-width: 1099px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    max-width: 400px !important;
  }
}
@media screen and (min-width: 885px) and (max-width: 989px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    left: 186px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    max-width: 440px !important;
    top: 30px;
    left: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    left: 105px;
  }
}
@media screen and (min-width: 711px) and (max-width: 767px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    left: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    top: 25px;
    left: 15px;
  }
}
@media screen and (max-width: 460px) {
  .hero-area-v-2 .hero-area-right .download-blob {
    max-width: 392px !important;
    left: -16px;
  }
}
