.sidebar-container {
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
  background-color: #ffffff;
  z-index: 99999;
}

.main-content {
  margin-left: 250px;
  overflow-x: auto;
}

.dashboardheader-container {
  width: 100%;
}

.content-container {
  width: 100%;
  background-color: #fff !important;
}

@media (max-width: 1024px) {
  .sidebar-container {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .main-content {
    margin-left: 0;
  }
}
