.carousel {
  max-width: 65vw;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.carousel-inner {
  height: 100%;
  width: 100%;
}

.carousel-inner img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.carousel-caption {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  height: 100%;
  left: 0 !important;
  right: 0 !important;
  top: 20rem;
  padding: 2rem;
  text-align: left !important;
}

.carousel-caption h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.carousel-caption p {
  font-size: 16px;
}

.carousel-indicators {
  margin-bottom: 2rem;
}

.carousel-indicators button {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #fecc18 !important;
}

/* Optional: Add custom transitions */
.carousel-item {
  transition: transform 0.6s ease-in-out;
}

.nav-tabs {
  width: 100%;
  border-bottom: 1px solid #dee2e6;
  position: relative;
  z-index: 2;
}
.nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: none;
  padding: 24px 10px;
  flex: 1;
}
.nav-tabs .nav-link i {
  margin-right: 4px;
}
.nav-tabs .nav-link:not(:last-child):hover,
.nav-tabs .nav-link:not(:last-child).active {
  border: none;
  color: #fecc18;
  font-weight: 800;
  background-color: #3d3e3e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nav-tabs .nav-link:is(:last-child):hover,
.nav-tabs .nav-link:is(:last-child).active {
  border: none;
  color: #fecc18;
  font-weight: 800;
  background-color: #3d3e3e;
  border-top-right-radius: 10px;
  border-top-left-radius: 0px;
}

.form-control-sm {
  height: 40px !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
}

.nav-tabs .nav-link {
  font-size: 0.975rem;
}

/* Forms */
@media screen and (max-width: 767px) {
  .auth_form {
    height: 100dvh;
  }
  /* .auth-form {
    max-height: 100vh;
  } */
  .form-control-sm {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 528px) {
  .auth_form {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .auth-form {
    max-height: 100dvh !important;
  }
  .auth-form .logo {
    margin-bottom: 10px !important;
  }
  .form-control-sm {
    font-size: 16px !important;
  }
}
