.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* Add some hover effects */
/* .card:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
} */

/* Responsive adjustments */
@media (max-width: 992px) {
  .chart-container {
    min-height: 250px;
  }
}
