.footer-div {
  position: relative;
}

.footer-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.footer-shape svg {
  position: relative;
  display: block;
  width: calc(161% + 1.3px);
  height: 150px;
}

.footer-shape .shape-fill {
  fill: #ffffff;
}

.push {
  height: 61px;
}

.footer-nav-div {
  background-color: #3d3e3e;
  /* background-color: black; */
  padding: 160px 0px 80px 0px;
}
.footer-nav-div .footer-brand {
  margin-bottom: 25px;
}
.footer-nav-div h4,
.footer-nav-div .h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}
.footer-nav-div .helpline h3,
.footer-nav-div .helpline .h3 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
}
.footer-nav-div .helpline h3 span,
.footer-nav-div .helpline .h3 span {
  color: #fecc18;
  display: inline-block;
}
.footer-nav-div .footer-navigation {
  display: flex;
}
.footer-nav-div .footer-navigation ul {
  flex-basis: 50%;
}
.footer-nav-div .footer-navigation ul a {
  transition: all 0.1s;
  display: inline-block;
}
.footer-nav-div .footer-navigation ul a:hover {
  color: #fecc18;
}
.footer-nav-div .app-download-box {
  display: flex;
}
@media only screen and (max-width: 991px) {
  .footer-nav-div .app-download-box {
    justify-content: center;
    margin-top: 20px;
  }
}
.footer-nav-div .app-download-box a {
  display: inline-block;
  margin-bottom: 30px;
  border: 1px solid #fff;
  border-radius: 3px;
}
.footer-nav-div .app-download-box a:first-child {
  margin-right: 5px;
}
.footer-nav-div .cta-button a {
  width: 100%;
  display: block;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid #fff;
}
.footer-nav-div .cta-button a:hover {
  border-color: #000000;
}
.footer-nav-div .cta-button a.button-light {
  color: #3b3b3b;
}
.footer-nav-div .cta-button a.button-light:hover {
  color: #fecc18;
}
.footer-nav-div a,
.footer-nav-div p {
  color: #fff;
}
.footer-nav-div p {
  word-break: break-all;
}

.copyright-div {
  background-color: #383939;
  color: #fff;
  padding: 15px 0;
}
@media only screen and (max-width: 991px) {
  .copyright-div {
    text-align: center;
  }
}
.copyright-div p {
  margin: 0;
}
.copyright-div p,
.copyright-div a {
  color: #fff;
  padding: 5px;
}

.footer-navigation li:not(:last-child) {
  margin-bottom: 6px;
}

.social-nav {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
@media only screen and (max-width: 991px) {
  .social-nav {
    justify-content: center;
    margin-top: 20px;
  }
}
.social-nav a {
  padding: 5px 12px;
  transition: all 0.1s;
  display: inline-block;
}

.social-nav a.facebook:hover {
  color: #3b5998;
}
.social-nav a.twitter:hover {
  color: #1da1f2;
}
.social-nav a.instagram:hover {
  color: #c32aa3;
}
.social-nav a.google-p:hover {
  color: #db4439;
}
.social-nav a.linkedin:hover {
  color: #007bb6;
}
.social-nav a.pinterest:hover {
  color: #bd081c;
}

.footer-nav-div.theme-2 {
  padding: 50px 0;
}
@media only screen and (max-width: 991px) {
  .footer-nav-div.theme-2 {
    text-align: center;
  }
}
.footer-nav-div.theme-2 .social-nav {
  justify-content: center;
}
.footer-nav-div.theme-2 .app-download-box a {
  margin-bottom: 0;
}
.footer-nav-div.theme-2 .footer-brand {
  margin: 0;
}

@media screen and (max-width: 575px) {
  .search-bar {
    max-width: 270px;
  }
  .header__lower {
    margin-top: 0;
  }
  .footer-nav-div .app-download-box {
    justify-content: flex-start;
  }
  .footer-nav-div .row > div:not(:last-child) {
    margin-bottom: 30px;
  }
  .footer-nav-div h4,
  .footer-nav-div .h4 {
    margin-bottom: 18px;
  }
}

.footer-div #gmap_canvas {
  width: 100%;
  height: 340px;
}
@media only screen and (max-width: 991px) {
  .footer-div #gmap_canvas {
    height: 200px;
  }
}
