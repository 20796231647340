/* ---------Hero-Slider-Css-Start------------------ */
/* hero slider wraper */
.banner_section {
  position: relative;
}

.banner_section .container {
  position: relative;
}

/* wave backgound after banner */
.banner_section::after {
  content: "";
  display: block;
  background-image: url(/public/enc/images/banner-shape.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: center;
  margin-top: -50px;
}

.banner_section .row {
  align-items: center;
}

/* hero slider text */
.banner_section .banner_text {
  margin-top: -50px;
}

/* hero slider heading h1 */
.banner_section .banner_text h1 {
  font-size: 55px;
  color: #32236f;
  letter-spacing: -1.5px;
  font-weight: 700;
}

.banner_section .banner_text h1 span {
  color: #96298d;
}

/* hero slider button */
.banner_section .app_btn {
  display: flex;
  align-items: center;
  padding-left: 0;
}

/* hero slider list */
.banner_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid #96298d;
  position: relative;
  border-radius: 12px;
  transition: 0.4s all;
}

.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

.banner_section .app_btn li a img {
  transition: 0.4s all;
}

.banner_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);

  opacity: 0;
}

/* hero slider users */
.banner_section .used_app {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -15px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
}

/* hero slider images */
.banner_section .banner_slider {
  display: flex;
  position: relative;
}

.banner_section .banner_slider .left_icon {
  position: absolute;
  left: 15px;
  bottom: 70px;
}

.banner_section .banner_slider .right_icon {
  position: absolute;
  right: 15px;
  top: 70px;
}

/* hero slider mobile frame */
.banner_section .banner_slider .slider_frame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 315px;
}

.banner_section .banner_slider #frmae_slider::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: #fff;
  width: calc(100% - 10px);
  height: 92%;
}

.banner_section .banner_slider::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 475px;
  height: 475px;
  border-radius: 100%;
  background-color: #96298d;
  z-index: -1;
}

.banner_section .banner_slider #frmae_slider {
  width: 305px;
  margin: 0 auto;
}

/* hero slider control dots */
.banner_section .owl-dots {
  margin-top: 40px;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
}

.waitlist_btn button {
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
  background-color: #96298d;
  color: #fff;
  border-radius: 50px;
  border-color: #96298d;
  padding: 10px 34px;
  width: max-content;
}

.waitlist_btn button a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.waitlist_btn button:hover {
  background-color: #b020a4;
}
