/* -----------Header-Css-Start------------------- */
/* header wraper */
header {
  position: relative;
  width: 100%;
  z-index: 99999;
  transition: 0.4s all;
}

header.fix_style {
  position: fixed;
  top: 0;
  backdrop-filter: blur(5px);
  background-color: #ffffffb4;
  padding: 0;
  transition: none;
  opacity: 0;
  pointer-events: none;
}

header.fixed {
  pointer-events: all;
  opacity: 1;
  transition: 0.4s all;
}

header.fixed .navbar {
  padding: 0;
}

/* navigation bar */
.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 6px;
}

.navbar-expand-lg .navbar-nav {
  transition: all 0.3s ease;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 5px 20px !important;
  font-weight: 600;
  font-size: 16px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #96298d;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  color: #fff;
  background-color: #96298d;
  font-size: 16px;
  padding: 9px 40px !important;
  border-radius: 25px;
  margin-left: 20px;
  position: relative;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before,
.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  z-index: -1;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
  animation: pulse-blue-medium-sm 3.5s infinite;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
  animation: pulse-blue-small-sm 3.5s infinite;
}

/* navigation bar dropdown */
.navbar-collapse.show {
  max-height: 500px;
  opacity: 1;
  transition: max-height 1s ease, opacity 1s ease !important;
}
.navbar-collapse:not(.show) {
  transition: none;
}

.dropdown-menu {
  background-color: white !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
  padding: 6px !important;
  align-self: flex-start !important;
  right: 0 !important;
}

.dropdown-menu .dropdown-item:hover {
  color: #96298d !important;
  background-color: #f7f1f1 !important;
}

/* navigation toggle menu */
.toggle-wrap {
  padding: 4px;
  position: relative;
  cursor: pointer;

  /*disable selection*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.toggle-bar {
  width: 25px;
  margin: 10px 0;
  position: relative;
  border-top: 4px solid #3e3f66;
  display: block;
}

.toggle-bar::before,
.toggle-bar::after {
  content: "";
  display: block;
  background: #3e3f66;
  height: 4px;
  width: 30px;
  position: absolute;
  top: -12px;
  right: 0px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -ms-transform-origin: 13%;
  -webkit-transform-origin: 13%;
  transform-origin: 13%;
}

.white_header .toggle-bar,
.white_header .toggle-bar::before,
.white_header .toggle-bar::after {
  border-top: 4px solid var(--bg-white);
}

.toggle-bar::after {
  top: 4px;
}

.toggle-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.toggle-wrap.open {
  transform: rotate(90deg);
}

.ico_menu {
  transition: transform 0.3s ease;
}

.ico_menu.close {
  transform: rotate(180deg);
}

/*------Header Btn Animation------*/
@keyframes pulse-blue-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 73, 242, 0.7);
  }

  100%,
  30% {
    box-shadow: 0 0 0 12px transparent;
  }
}

@keyframes pulse-blue-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 73, 242, 0.4);
  }

  100%,
  30% {
    box-shadow: 0 0 0 20px transparent;
  }
}
