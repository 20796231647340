/* -----------------About-App-Section-Css-Start------------------ */

/* about us section wraper */
.about_app_section .about_img {
  display: flex;
  align-items: center;
  position: relative;
}

/* about us section images*/
.about_app_section .about_img img {
  max-width: 100%;
}

.about_app_section .about_img::before {
  content: "";
  position: absolute;
  left: 38%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: var(--bg-white);
  border-radius: 100%;
  z-index: -1;
}

.about_app_section .about_img .screen_img {
  margin-left: -135px;
  margin-top: 110px;
}

.about_app_section .about_text .section_title {
  text-align: left;
}

.about_app_section .about_text .section_title h2 {
  margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 40px;
  padding-left: 0;
  margin-right: 60px;
}

.about_app_section .about_text .app_statstic li {
  width: 45%;
  background-color: var(--bg-white);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 12px;
  padding: 15px 10px;
  box-shadow: 0px 4px 10px #ede9fe;
}

.about_app_section .about_text .app_statstic li .icon {
  margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
  margin-bottom: 0;
  line-height: 1;
  color: var(--dark-purple);
}

.about_app_section .about_text .app_statstic li p:first-child {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3px;
}

/* purple button */
.puprple_btn {
  background-color: #96298d !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 40px !important;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.puprple_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--bg-white);
  border-radius: 50px;
  transition: 0.6s all;
  z-index: -1;
}

.puprple_btn:hover::before {
  width: 100%;
}

.puprple_btn:hover {
  color: #96298d !important;
  border: 1px solid #96298d;
}
