/* VerifyAccountModal.css */
#verification-modal {
  overflow-y: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: hidden;
}

#verification-modal.show {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

#verification-modal.hide {
  opacity: 0;
  transform: scale(0.95);
  visibility: hidden;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.modal-dialog {
  max-height: 300px;
}

.btn-verified {
  border: 1px solid #06d748 !important;
  color: #06d748 !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  #verification-modal {
    text-align: left;
    padding: 0;
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    flex: 1;
    border-radius: 0;
    padding-bottom: 30px;
    overflow-y: auto;
    height: 100vh !important;
  }

  .modal-body,
  .modal-header {
    padding: 1rem !important;
  }

  .upload-area {
    padding: 1rem;
  }

  .form-control,
  .form-select {
    font-size: 16px;
  }

  /* .modal-body form {
    padding-bottom: 2rem;
  } */

  .verify-illustration img {
    width: 20rem !important;
  }
}

@media (max-width: 529px) {
  #verification-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s;
  }

  #verification-modal.show {
    opacity: 1;
    visibility: visible;
  }

  #verification-modal.hide {
    opacity: 0;
    visibility: hidden;
  }

  #verification-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  #verification-modal.show .modal-dialog {
    transform: translateY(0);
  }

  #verification-modal.hide .modal-dialog {
    transform: translateY(100%);
  }

  #verification-modal .modal-content {
    max-height: 100vh;
    overflow-y: auto;
  }
  .verify-illustration img {
    width: 15rem !important;
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    padding-top: 20px;
    flex: 1;
    border-radius: 0;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
  }

  .modal-body {
    padding: 1rem !important;
  }
  /* .modal-btn {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  } */
  .modal-btn .btn-light,
  .modal-btn .btn-purple {
    flex: 1 !important;
    width: auto !important;
  }
}

/* Additional utility classes */
.overflow-y-auto {
  overflow-y: auto;
}

.h-100 {
  height: 100%;
}

/* Custom hover styles */
button:hover .verify-id {
  background-color: lightgray !important;
  color: black !important;
}

button.contact-us:hover {
  background-color: #c633bc !important;
}
