/* -------------How_It_Works-Section-Css-Start------------------ */
:root {
  --light-purple: #f6f4fe;
  --purple: #6a49f2;
  --bg-purple: #6a49f2;
  --dark-purple: #32236f;
  --body-text-purple: #3e3f66;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --slider-dots-color: #d4d2dd;
  --light-bg: #dfdaf3;
}

/* how it works wraper */
.how_it_works .container {
  max-width: 1370px;
}

.how_it_works .how_it_inner {
  background-color: #fff;
  padding: 70px 0;
  padding-bottom: 80px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px #ede9fe;
}

/* how it works list */
.how_it_works .step_block ul {
  max-width: 1080px;
  margin: 0 auto;
  padding: 10px;
}

.how_it_works .step_block ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
}

.how_it_works .step_block ul li::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  width: 4px;
  height: calc(100% + 100px);
  background-color: #dfdaf3;
}

.how_it_works .step_block ul li:first-child::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  background-color: #dfdaf3;
  border-radius: 15px;
}

.how_it_works .step_block ul li:first-child::before {
  top: 0;
}

.how_it_works .step_block ul li:last-child::before {
  height: 50%;
  top: 0;
}

.how_it_works .step_block ul li .step_text,
.how_it_works .step_block ul li .step_img {
  width: 360px;
  text-align: right;
}

/* how it works image */
.how_it_works .step_block ul li .step_img img {
  max-width: 100%;
}

/* how it works heading h4 */
.how_it_works .step_block ul li .step_text h4 {
  font-size: 20px;
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text .app_icon {
  margin-bottom: 10px;
}

.how_it_works .step_block ul li .step_text .app_icon a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #dfdaf3;
  color: var(--text-white);
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transition: 0.4s all;
}

.how_it_works .step_block ul li .step_text .app_icon a:hover {
  background-color: #96298d;
}

.how_it_works .step_block ul li .step_text span {
  font-weight: 600;
}

.how_it_works .step_block ul li .step_text span a {
  color: #96298d;
  text-decoration: underline;
}

.how_it_works .step_block ul li:nth-child(2) {
  flex-direction: row-reverse;
}

.how_it_works .step_block ul li:nth-child(2) .step_text,
.how_it_works .step_block ul li:nth-child(2) .step_img {
  text-align: left;
}

/* how it works numbers */
.how_it_works .step_block ul li .step_number {
  background-image: url(/public/enc/images/icon_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* how it works numbers heading h3 */
.how_it_works .step_block ul li .step_number h3 {
  font-size: 30px;
  font-weight: 600;
}

/* how it works video  */
.how_it_works .yt_video {
  max-width: 1170px;
  margin: 0 auto;
  margin-top: -200px;
  position: relative;
  overflow: hidden;
}

/* how it works video animation line  */
.how_it_works .yt_video .anim_line {
  z-index: 999;
}

.how_it_works .yt_video .thumbnil {
  position: relative;
}

.how_it_works .yt_video .thumbnil img {
  max-width: 100%;
}

.how_it_works .yt_video .thumbnil a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  z-index: 999;
  cursor: pointer;
}

.how_it_works .yt_video .thumbnil a span {
  display: block;
  font-weight: 700;
  font-size: 30px;
}

.how_it_works .yt_video .thumbnil a .play_btn {
  background-color: rgba(255, 255, 255, 0.1);
  width: 96px;
  height: 96px;
  border-radius: 100px;
  text-align: center;
  margin: 0 auto;
  line-height: 96px;
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.how_it_works .yt_video .thumbnil a .play_btn img {
  width: 50px;
  position: relative;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  /* how it work section */
  .how_it_works .how_it_inner {
    padding: 50px 15px;
  }
  .how_it_works .step_block ul {
    padding-top: 30px;
  }
  .how_it_works .step_block ul li,
  .how_it_works .step_block ul li:nth-child(2) {
    flex-direction: column;
    padding-left: 30px;
  }
  .how_it_works .step_block ul li .step_text h4 {
    font-size: 18px;
  }
  .how_it_works .step_block ul li::before {
    left: 0;
    transform: none;
    height: calc(100% + 10px);
    top: 40px;
  }
  .how_it_works .step_block ul li:first-child::before {
    height: calc(100% + 50px);
  }
  .how_it_works .step_block ul li:first-child::after {
    left: -5px;
    transform: none;
    display: none;
  }
  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img,
  .how_it_works .step_block ul li:nth-child(2) .step_text,
  .how_it_works .step_block ul li:nth-child(2) .step_img {
    text-align: center !important;
  }
  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img {
    width: 100%;
  }
  .how_it_works .step_block ul li .step_number {
    position: absolute;
    top: -5px;
    left: -23px;
    width: 50px;
    height: 50px;
  }
  .how_it_works .step_block ul li:last-child::before {
    opacity: 0;
  }
  .how_it_works .step_block ul li .step_number h3 {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: -2px;
  }
  .how_it_works .yt_video {
    margin-top: -50px;
  }
  .how_it_works .yt_video .thumbnil {
    height: 300px;
    border-radius: 15px;
  }
  .how_it_works .yt_video .thumbnil img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
  }
  .how_it_works .yt_video .thumbnil a {
    width: 100%;
  }
  .how_it_works .yt_video .thumbnil a .play_btn::after {
    width: 140px;
    height: 140px;
  }
  .how_it_works .yt_video .thumbnil a .play_btn::before {
    width: 120px;
    height: 120px;
  }
  .how_it_works .yt_video .thumbnil a .play_btn img {
    height: auto;
  }
}
