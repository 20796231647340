@media only screen and (max-width: 1200px) {
  .nav-link {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .header__upper--right .navigation a,
  .header__upper--right .button {
    font-size: 13px !important;
  }
}

.header__upper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.8s all;
}
.header__upper .row {
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .header__upper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  }
}
.header__upper--left {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .header__upper--left {
    justify-content: space-around;
  }
}
.header__upper--left .logo {
  margin-right: 25px !important;
}
@media only screen and (max-width: 575px) {
  .header__upper--left .logo {
    margin-right: 15px;
  }
}
.header__upper--left .nav-toggle-btn {
  margin-left: auto;
  margin-right: inherit;
  color: #000;
  margin-left: 187px;
}
.header__upper--left .nav-toggle-btn {
  background: 0 0;
  border: none;
  border-radius: 0;
  outline: none;
  position: relative;
  z-index: 995;
  font-weight: 700;
  text-transform: uppercase;
  color: inherit;
  display: inline-block;
  margin-left: 70px;
}
.header__upper--left .nav-toggle-btn:focus {
  outline: none;
}
.header__upper--left .nav-toggle,
.header__upper--left .nav-toggle-title {
  vertical-align: middle;
  color: #000;
}
.header__upper--left .nav-toggle {
  width: 29px;
  height: 29px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.header__upper--left .stick {
  width: 29px;
  height: 5px;
  margin-bottom: 5px;
  background-color: #000;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.header__upper--left .nav-toggle-btn.white .stick {
  background-color: #fff;
}
.header__upper--left .nav-toggle-btn.white .nav-toggle-title {
  color: #fff;
  margin-right: 20px;
}
.header__upper--left .body-menu-opened .nav-toggle-btn {
  color: #000;
}
.header__upper--left .body-menu-opened .nav-toggle-btn .stick {
  background-color: #000;
}
.header__upper--left .stick:last-child {
  margin-bottom: 0;
}
.header__upper--left .nav-toggle:hover .stick-2 {
  width: 29px;
}
.header__upper--left .body-menu-opened .stick-1 {
  animation: Bc 0.85s ease-out forwards;
}
.header__upper--left .body-menu-opened .stick-2 {
  animation: Cc 0.85s linear forwards;
  width: 29px;
  margin-left: 0;
}
.header__upper--left .body-menu-opened .stick-3 {
  animation: Dc 0.85s linear forwards;
}
@keyframes Bc {
  0% {
    width: 29px;
  }
  30% {
    width: 3px;
    transform: translate(14px);
  }
  65% {
    width: 3px;
    transform: translate(14px, -29px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  to {
    width: 2px;
    transform: translate(9px, 17px);
  }
}
@keyframes Cc {
  65% {
    width: 29px;
    transform: translate(0) rotate(0deg);
  }
  to {
    width: 29px;
    transform: translate(0) rotate(45deg);
  }
}
@keyframes Dc {
  65% {
    transform: translate(0) rotate(0deg);
  }
  to {
    transform: translateY(-8px) rotate(-45deg);
  }
}
.header__upper--left .body-menu-close .stick-1 {
  width: 3px;
  animation: Ec 0.85s ease-out forwards;
}
.header__upper--left .body-menu-close .stick-2 {
  animation: Fc 0.85s ease-out forwards;
  margin-left: 0;
}
.header__upper--left .body-menu-close .stick-3 {
  animation: Gc 0.85s ease-out forwards;
}
@keyframes Ec {
  0%,
  70% {
    width: 0;
    transform: translate(0);
  }
  to {
    width: 29px;
    transform: translate(0);
  }
}
@keyframes Fc {
  0% {
    width: 29px;
    transform: translate(0) rotate(45deg);
  }
  20% {
    width: 3px;
    transform: translate(0) rotate(45deg);
  }
  40% {
    width: 0;
  }
  65% {
    transform: translateY(-26px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  80% {
    width: 0;
  }
  to {
    width: 29px;
    transform: translate(0);
  }
}
@keyframes Gc {
  0% {
    width: 29px;
    transform: translateY(-8px) rotate(-45deg);
  }
  20% {
    width: 3px;
    transform: translateY(-8px) rotate(-45deg);
  }
  65% {
    transform: translateY(-35px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  90% {
    width: 3px;
  }
  to {
    width: 29px;
    transform: translate(0);
  }
}
.header__upper--left .nav-toggle-btn.desktop-hide {
  display: none;
}
.header__upper--left .body-menu-opened .nav-toggle-btn.white .stick {
  background-color: #fff;
}
.header__upper--right {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 991px) {
  .header__upper--right {
    justify-content: space-evenly;
  }
  .header__upper--right .navigation ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}
.header__upper--right .navigation ul {
  margin: 0;
  padding-left: 0;
}
.header__upper--right .navigation li {
  display: inline-block;
}
.header__upper--right .navigation li:not(:last-child) {
  margin-right: 20px;
}
.header__upper--right .navigation a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.1s;
  color: #3b3b3b;
  text-transform: uppercase !important;
  text-decoration: none !important;
}
.header__upper--right .navigation a:hover {
  color: #fecc18;
}
@media only screen and (max-width: 575px) {
  .header__upper--right .navigation a {
    padding: 0;
  }
}
.header__upper--right .button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 40px;
  color: #3b3b3b;
  text-align: center;
  text-transform: uppercase !important;
  text-decoration: none !important;
}
.header__upper--right .button .icon {
  font-size: 16px;
  padding: 6px;
}
.header__upper--right .button:before {
  display: none;
}
.header__upper--right .button:hover {
  color: #fecc18;
}
.header__upper--right .button .icon {
  width: 20px;
  height: 20px;
  line-height: 40px;
  margin-right: 5px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  background-color: #96298d;
}

.collapse {
  visibility: visible !important;
}

.collapse:not(.show) {
  display: block !important;
}

.responsive-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999999999999;
  background-color: #fff;
  height: 100%;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: all 0.4s ease-in-out;
}
.responsive-menu.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  position: fixed;
}
.responsive-menu.open .mobile-menu,
.responsive-menu.open .search-box {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.responsive-menu .rep-header {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}
.responsive-menu .rep-header .lni {
  display: inline-block;
  font: normal normal normal 1em/1 "LineIcons";
  color: inherit;
  flex-shrink: 0;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.responsive-menu .rep-header .lni-close::before {
  content: "\ea6a";
}
.responsive-menu .close-menu {
  margin-left: auto;
  border: none;
  font-size: 30px;
  background: transparent;
}
.responsive-menu .mobile-menu {
  margin-top: 10px;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.4s;
  transform: translateY(3rem);
}
.responsive-menu .mobile-menu > li {
  display: block;
  border-bottom: 1px solid #eee;
  color: #999;
  font-size: 20px;
  position: relative;
}
.responsive-menu .mobile-menu > li a {
  padding: 10px;
  display: block;
  position: relative;
  transition: color 0.3s cubic-bezier(0.82, 0, 0.21, 1);
  overflow: hidden;
  color: #343a40;
  font-weight: 700;
}
.responsive-menu .mobile-menu > li a:after {
  content: attr(data-text);
  position: absolute;
  width: 0;
  transition: width 0.3s cubic-bezier(0.82, 0, 0.21, 1);
  color: #d5892d;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
}
.responsive-menu .mobile-menu > li a:hover:after {
  width: 100%;
}
.responsive-menu .mobile-menu > li a:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f59e31;
  position: absolute;
  top: 50%;
  right: 20px;
  color: #999;
  font-size: 36px;
  font-family: lineicons;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.responsive-menu .mobile-menu > li a i {
  margin-right: 5px;
}
.responsive-menu .mobile-menu > li.menu-item-has-children > a:before {
  opacity: 1;
  visibility: visible;
}
.responsive-menu .mobile-menu > li.menu-item-has-children.active > a {
  color: #f59e31;
}
.responsive-menu .mobile-menu > li.menu-item-has-children.active > a:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f59e31;
  font-size: 0;
  line-height: 0;
  float: left;
  font-size: 50px;
}
.responsive-menu .mobile-menu ul {
  display: none;
  padding: 25px 30px 30px;
}
.responsive-menu .mobile-menu ul li {
  display: block;
  margin-bottom: 10px;
}
.responsive-menu .mobile-menu ul li:last-child {
  margin-bottom: 0;
}
.responsive-menu .mobile-menu ul li a {
  display: block;
  color: #555;
  font-size: 18px;
  padding: 0;
  font-weight: 400;
  text-transform: capitalize;
}
.responsive-menu .mobile-menu ul li a.active,
.responsive-menu .mobile-menu ul li a:hover {
  color: #d5892d;
}

.header__lower {
  background-color: #3d3e3e;
}
.header__lower .navbar {
  padding: 10px 0;
}
.header__lower .dropdown-item {
  color: #fff;
}
.header__lower .dropdown-item:focus,
.header__lower .dropdown-item:hover {
  color: #fff;
  background: #383939;
}
.header__lower .navbar-dark .navbar-nav .nav-item.active .nav-link {
  color: #fecc18;
}
.header__lower .navbar-dark .navbar-nav > li > .nav-link {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
}
.header__lower .navbar-dark .navbar-nav > li:hover > .nav-link {
  color: #fecc18;
}
.header__lower .navbar-dark .navbar-nav > li:hover > .nav-link i {
  color: #fecc18;
}
.header__lower .navbar-dark .navbar-nav > li i,
.header__lower .navbar-dark .navbar-nav > li img {
  margin-right: 5px;
}

@media only screen and (max-width: 575px) {
  .header__lower {
    margin-top: 9px;
  }
  .header__lower .navbar-nav {
    margin-top: 10px;
  }
  .header__lower .navbar-dark .navbar-nav .nav-link i {
    padding: 0px 5px;
  }
}
.header__lower .button {
  padding: 19px 26px;
  border-radius: 50px;
}

.search-bar {
  width: 400px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .search-bar {
    line-height: 32px;
  }
}
.search-bar .form {
  display: flex;
}
.search-bar .form input:focus {
  background-color: #ffffff;
}
.search-bar .form input:focus + .button {
  color: #3b3b3b;
  background-color: #fecc18;
}
.search-bar .form .button {
  top: 0;
  right: 0;
  padding: 0;
  width: 53px;
  height: 60px;
  position: absolute;
  border-radius: 0 50px 50px 0;
}
.search-bar .form-control {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding-left: 46px;
  border-radius: 50px;
  color: #333333;
  background-color: #f7f7f6;
}
.search-bar .icon {
  top: 8px;
  left: 25px;
  height: 100%;
  line-height: 43px;
  position: absolute;
  display: inline-block;
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  top: 52px;
  z-index: 9;
}

#header {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
@media only screen and (max-width: 991px) {
  #header {
    position: relative;
  }
}

@media screen and (max-width: 991px) {
  .responsive-menu .header__upper--right .button {
    margin-left: 0;
  }
  .responsive-menu .header__upper--right .button .icon {
    width: 20px;
    height: 20px;
    line-height: 40px;
    margin-right: 5px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    background-color: #96298d;
  }
  .responsive-menu .header__upper--right {
    align-items: start;
    gap: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  #header.theme-2 .media img {
    margin: 0 !important;
  }
  #header.theme-2 .media-body {
    display: none;
  }
}
#header.theme-2 .header__upper {
  padding: 0;
}
#header.theme-2 .navigation ul,
#header.theme-2 .header__upper--left {
  padding: 13px 0;
}
@media only screen and (max-width: 575px) {
  #header.theme-2 .navigation ul,
  #header.theme-2 .header__upper--left {
    margin: 0;
  }
}
#header.theme-2 .header__upper--right .navigation {
  margin-right: 20px;
}
#header.theme-2 .header__upper--right .navigation a {
  padding: 11px 18px;
}
@media only screen and (max-width: 1200px) {
  #header.theme-2 .header__upper--right .navigation a {
    padding: 11px;
  }
}
@media only screen and (max-width: 575px) {
  #header.theme-2 .header__upper--right .navigation a {
    padding: 6px 4px;
  }
}
@media only screen and (max-width: 575px) {
  #header.theme-2 .navigation ul {
    padding: 10px 5px;
  }
}
#header.theme-2 .dropdown-toggle::after {
  display: none;
}
#header.theme-2 .dropdown-toggle {
  display: inline-block;
  padding: 16px;
  cursor: pointer;
}
@media only screen and (max-width: 575px) {
  #header.theme-2 .dropdown-toggle {
    padding: 10px;
  }
}
#header.theme-2 .dropdown-toggle h6,
#header.theme-2 .dropdown-toggle .h6 {
  font-size: 14px;
}

#header.theme-3 {
  padding: 24px 0;
  background-color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

.navigation-lower ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .navigation-lower ul {
    text-align: left;
    margin-top: 15px;
  }
}
.navigation-lower ul li {
  display: inline-block;
}
.navigation-lower ul li a {
  display: block;
  padding: 15px 20px;
  border: 1px solid transparent;
  line-height: 1;
  color: #3b3b3b;
}
@media only screen and (max-width: 575px) {
  .navigation-lower ul li a {
    padding: 10px 12px;
  }
}
.navigation-lower ul li a:hover {
  border-color: #3b3b3b;
}

/* Header Sticky - Start */
#header {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}

#header.sticky {
  position: fixed;
  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.2);
  animation: 0.4s ease-in-out 0s normal none 1 running fadeInDown;
}
#header.sticky .header__lower {
  display: none;
}

/* Header Sticky - End */
/* DropDown Style - Start */
.navbar-nav .dropdown-menu {
  padding: 10px 0;
  font-size: 15px;
  min-width: 190px;
  border-radius: 0;
  background-color: #383939;

  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}
.navbar-nav .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.navbar-nav .dropdown-menu > li:hover > a,
.navbar-nav .dropdown-menu > li.active > a {
  color: #fecc18;
  background-color: rgba(0, 0, 0, 0.15);
}
.navbar-nav .dropdown-menu .dropdown-item {
  line-height: 1;
  padding: 15px 25px;
  border-radius: 2px;
}

/* DropDown Style - End */

/* Button */
.button {
  z-index: 1;
  border: none;
  transition: 0.2s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 0.8;
  overflow: hidden;
  padding: 22px 30px;
  position: relative;
  border-radius: 50px;
  display: inline-block;
  text-transform: uppercase;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  text-decoration: none !important;
}
.button:before {
  top: 0;
  width: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: -1;
  content: "";
  position: absolute;
  transition: 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}
.button:hover {
  color: #ffffff;
}
.button:hover:before {
  left: 0;
  width: 100%;
  right: auto;
  background-color: #000000;
}
.button.big {
  text-align: center;
}
.button.tiny {
  width: 130px;
  text-align: center;
}
.button:hover {
  color: #fff;
}

.button-dark {
  color: #fff;
  position: relative;
  background-color: #3b3b3b;
}

.button-light {
  color: #373737;
  background-color: #fecc18;
}

.button-black {
  color: #fff;
  position: relative;
  background-color: #1d1d1d;
}

.responsive .button-light:hover {
  color: #fff !important;
}

@media (max-width: 768px) {
  .dropdown-menu {
    right: auto;
    left: 0;
    top: 60px;
    width: 100%;
  }
}

.mobile-profile-dropdown {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  z-index: 10;
}

@media (min-width: 992px) {
  .mobile-profile-dropdown {
    display: none;
  }
}
