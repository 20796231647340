/* -------------Modern-Ui-Section-Css-Start---------------- */
/* modern ui section wraper */
.modern_ui_section .row {
  align-items: center;
}

.modern_ui_section .design_block {
  margin-top: 45px;
  padding-left: 0;
}

/* modern ui text */
.modern_ui_section .section_title {
  text-align: left;
}

.modern_ui_section .ui_text {
  padding-right: 75px;
}

/* modern ui list */
.modern_ui_section .design_block li {
  padding-left: 40px;
  position: relative;
  margin-bottom: 25px;
}

.modern_ui_section .design_block li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  background-image: url(/public/enc/images/right_icon.png);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.modern_ui_section .design_block li h4 {
  font-size: 20px;
  color: var(--dark-purple);
  font-weight: 600;
  margin-bottom: 8px;
}

.modern_ui_section .design_block li p {
  margin-bottom: 0;
}

/* modern ui images */
.modern_ui_section .ui_images {
  display: flex;
  position: relative;
}

.modern_ui_section .ui_images::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 570px;
  border-radius: 100%;
  background-color: var(--bg-white);
  z-index: -1;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  margin-left: -140px;
  margin-top: -20px;
  width: 220px;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  margin-left: -90px;
  margin-top: -20px;
  width: 250px;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  position: relative;
  top: 15px;
  z-index: 0;
  margin-left: -15px;
  width: 100px;
}
