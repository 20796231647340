/* ----------Feature-Detail-Section-start------ */

/* features section wraper */
.features_section .feature_detail {
  background-color: var(--bg-white);
  border-radius: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding-top: 70px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 30px #ede9fe;
}
.features_section .feature_detail .read-more-link {
  color: #3498db;
  cursor: pointer;
  margin-left: 5px;
}
/* features section image */
.features_section .feature_detail .feature_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
  top: 25px;
}

.features_section .feature_detail .feature_img img {
  max-width: 100%;
}

/* features section box */

.features_section .feature_detail .feature_box {
  max-width: 410px;
}

.features_section .feature_detail .feature_box .data_block {
  margin-bottom: 50px;
}

.features_section .feature_detail .feature_box .data_block h4 {
  font-size: 20px;
  color: var(--dark-purple);
  font-weight: 600;
}

.features_section .feature_detail .left_data {
  text-align: right;
  padding-left: 35px;
}

.features_section .feature_detail .right_data {
  padding-right: 35px;
}

.features_section .feature_detail .left_data .data_block .icon {
  margin-right: -15px;
}

.features_section .feature_detail .left_data .data_block .icon img {
  filter: drop-shadow(2px 10px 4px #9191eb);
}

.features_section .feature_detail .right_data .data_block .icon img {
  filter: drop-shadow(2px 10px 4px #9191eb);
}

.features_section .feature_detail .right_data .data_block .icon {
  margin-left: -15px;
}

.features_section .container {
  max-width: 1370px;
}
