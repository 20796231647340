/* Bredcrumb Css Start */
.bred_crumb {
  /* background-image: url(../images/bread_crumb_bg.png); */
  background: #96298d;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 4px;
  font-family: "Poppins", sans-serif;
}

.bred_crumb .bred_text {
  text-align: center;
  z-index: 1000;
  position: relative;
}

.bred_crumb .bred_text h1 {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
}

.bred_crumb .bred_text h1 + p {
  color: var(--text-white);
  margin-top: -5px;
}

/* Inner Page banner shape animation */
.bred_crumb .banner_shape1,
.banner_shape2,
.banner_shape3 {
  position: absolute;
}

.bred_crumb .banner_shape1 {
  top: 200px;
  left: 15%;
  animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
  top: 100px;
  right: 10%;
  animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
  top: 350px;
  right: 20%;
  animation: mymove 3s infinite;
}
