/* -----------Animation-Css-Start-------------- */

/* animation line wraper */
.anim_line {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.anim_line span {
  position: absolute;
  z-index: 99999;
  top: -275px;
  animation: star_down_one 6s infinite linear;
  opacity: 0;
}

.anim_line.dark_bg {
  max-width: 1170px;
}

.anim_line.dark_bg span {
  transform: rotate(180deg);
}

.anim_line span:first-child {
  left: -17%;
  animation-delay: 3s;
}

.anim_line span:nth-child(2) {
  left: 0%;
  animation-delay: 5s;
}

.anim_line span:nth-child(3) {
  left: 17%;
  animation-delay: 1s;
}

.anim_line span:nth-child(4) {
  left: 34%;
  animation-delay: 4s;
}

.anim_line span:nth-child(5) {
  left: 51%;
  animation-delay: 7s;
}

.anim_line span:nth-child(6) {
  left: 68%;
}

.anim_line span:nth-child(7) {
  left: 85%;
  animation-delay: 3s;
}

.anim_line span:nth-child(8) {
  left: 99%;
  animation-delay: 2s;
}

.anim_line span:nth-child(9) {
  left: 117%;
  animation-delay: 5s;
}

@keyframes star_down_one {
  0% {
    opacity: 0;
    top: -250px;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    top: 100%;
    opacity: 0;
  }
}

/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
  animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
  position: relative;
  animation: moving_position_animatin 6s infinite linear;
  z-index: -1;
}

.about_app_section .about_img .screen_img img {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
  animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
  animation-delay: 3s;
}

@keyframes moving_object {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes moving_position_animatin {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}
